import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { HeaderProfileComponent } from "./bancreachwebsite/component/header-profile/header-profile.component";
import { HttpsService } from "./bancreachwebsite/services/http/http.service";
import { SwPush, SwUpdate } from "@angular/service-worker";
import firebase from "firebase/app";
import "firebase/messaging";
import { environment } from "src/environments/environment";
import * as Notiflix from "notiflix";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild(HeaderProfileComponent, { static: false })
  private HeaderProfileComponent: HeaderProfileComponent;

  title = "bancreach";
  user: any;
  serviceWorkerAttempt = 0;
  notificationActions: any;

  constructor(
    private router: Router,
    private http: HttpsService,
    updates: SwUpdate,
    push: SwPush
  ) {
    // navigator.serviceWorker.register("ngsw-worker.js");
    // firebase.initializeApp(environment.firebaseConfig);
    // const setInt = () => {
    //   navigator.serviceWorker.getRegistration().then((swr: any) => {
    //     this.serviceWorkerAttempt++;
    //     console.log("swr", swr);
    //     if (swr != undefined) {
    //       firebase.messaging().useServiceWorker(swr);
    //     } else {
    //       if (this.serviceWorkerAttempt > 0 && this.serviceWorkerAttempt < 5) {
    //         console.log("ok" + this.serviceWorkerAttempt);
    //         setInt();
    //       }
    //     }
    //   });
    // };
    // setInt();
    // updates.available.subscribe((_) =>
    //   updates.activateUpdate().then(() => {
    //     console.log("reload for update");
    //     document.location.reload();
    //   })
    // );
    // push.messages.subscribe((msg: any) => {
    //   console.log("push message1", msg.data);
    //   this.notificationActions = JSON.parse(msg.data["gcm.notification.data"]);
    //   console.log(
    //     "push message2",
    //     JSON.parse(msg.data["gcm.notification.data"])
    //   );
    // });
    // push.notificationClicks.subscribe((click: any) => {
    //   console.log("notification click", click);
    //   click.notification.data = this.notificationActions;
    //   click.notification.data.onActionClick.default.operation;
    // });
    // const self = window as any;
    // self.addEventListener("notificationclick", function (event: any) {
    //   console.log("notification click event", event);
    //   var redirect_url = event.notification.data.click_action;
    //   event.notification.close();
    //   event.waitUntil(
    //     self.clients
    //       .matchAll({
    //         type: "window",
    //       })
    //       .then(function (clientList) {
    //         for (var i = 0; i < clientList.length; i++) {
    //           var client = clientList[i];
    //           if (client.url == "/" && "focus" in client) return client.focus();
    //         }
    //         if (self.clients.openWindow) return self.clients.openWindow("/");
    //       })
    //   );
    // });
    // Notiflix.Confirm.init({
    //   className: 'notiflix-confirm',
    //   width: '300px',
    //   zindex: 4003,
    //   position: 'center', // 'center' - 'center-top' - 'center-bottom' - 'right-top' - 'right-center' - 'right-bottom' - 'left-top' - 'left-center' - 'left-bottom'
    //   distance: '10px',
    //   backgroundColor: '#f8f8f8',
    //   borderRadius: '25px',
    //   backOverlay: true,
    //   backOverlayColor: 'rgba(0,0,0,0.5)',
    //   rtl: false,
    //   fontFamily: 'Quicksand',
    //   cssAnimation: true,
    //   cssAnimationDuration: 300,
    //   cssAnimationStyle: 'fade', // 'zoom' - 'fade'
    //   plainText: true,
    
    //   titleColor: '#5890c5',
    //   titleFontSize: '16px',
    //   titleMaxLength: 34,
    
    //   messageColor: '#5890c5',
    //   messageFontSize: '14px',
    //   messageMaxLength: 110,
    
    //   buttonsFontSize: '15px',
    //   buttonsMaxLength: 34,
    //   okButtonColor: '#f8f8f8',
    //   okButtonBackground: '#5890c5',
    //   cancelButtonColor: '#f8f8f8',
    //   cancelButtonBackground: '#a9a9a9',
    // });
  }

  

  ngOnInit() {
    localStorage.setItem("isStart", "false");
    this.user = JSON.parse(localStorage.getItem("bancreach_web_user"));
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    // setTimeout(() => {
    //   console.log("run");
    //   this.permitToNotify();
    // }, 5000);
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  permitToNotify() {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() =>
        messaging.getToken().then((token: any) => {
          console.log(token);
          localStorage.fcmToken = token;
        })
      )
      .catch((err: any) => {
        console.log("Unable to get permission to notify.", err);
      });
  }
}
