// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//ng build --base-href /bancreach/web/ 

export const environment = {
  production: false,
  backendUrl: "https://demo.appdukaan.com:4001/",
  imageUrl: "https://demo.appdukaan.com:4001/",
  socketUrl: "ws://python.webdevelopmentsolution.net:8888/ws/chat/",
  // backendUrl: "https://api.bancreach.com/api",
  // imageUrl: "https://api.bancreach.com",
  // socketUrl: "wss://api.bancreach.com/ws/chat/",
  googleApiKey: "AIzaSyD25wMsCjO5PIml6KC2QWL8rpHeZlN2fKE",
  linkPreviewKey: "c49776a3422741c25e5685cb8c5f01f6",
  firebaseConfig : {
    apiKey: "AIzaSyAXCH52EsRrclgFBB3YjqWxn_hVnyFJbf4",
    authDomain: "bancreach-d64c8.firebaseapp.com",
    projectId: "bancreach-d64c8",
    storageBucket: "bancreach-d64c8.appspot.com",
    messagingSenderId: "721874445055",
    appId: "1:721874445055:web:98b9d895994f7e753ea2ca",
    measurementId: "G-YW5WMT6HVY"
  }

  // backendUrl: 'http://cicd.appgrowthcompany.com:8888/api',
  // imageUrl: "http://cicd.appgrowthcompany.com:8888",
  // socketUrl: "ws://cicd.appgrowthcompany.com:8888/ws/chat/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
