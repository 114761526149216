export class apiList {
  login = "/auth/login";
  businessLogin = "/auth/bussiness/login/";
  signUp = "/auth/sign-up/";
  uploadMedia = "/upload/media/";
  createFeed = "/feed/create/";
  getFeedList = "/feed/list-with-pagintaion/";
  likefeed = "/feed/like/";
  deleteFeed = "/feed/delete/";
  resendVarificationEmail = "/user/resend-varification-email/";
  profileStatus = "/user/check-profile-status/";
  sectorList = "/sector/list/";
  updateUserDetails = "/user/update-user-details/";
  forgotPassword = "/auth/forgot-website-password/";
  resetPassword = "/auth/reset-password/";
  businessSignUp = "/auth/bussiness-sign-up/";
  getBussinessTypes = "/user/get-bussiness-types/";
  getBussinessSizes = "/user/get-bussiness-sizes/";
  updateFeed = "/feed/update/";
  profileDetails = "/user/profile-details/";
  getCommentList = "/feed/commets-pagination-list-feed-id/";
  createComment = "/feed/create-comment/";
  deleteComment = "/feed/delete-comments/";
  notificationCount = "/user/notification-count/";
  notificationList = "/user/notification-list/";
  notificationViewCount = "/user/view-count/";
  updateProfile = "/user/update-user-details-by-id/";
  addWorkExperience = "/user/create-work-experience/";
  updateWorkExperience = "/user/update-work-experience-by-pk/";
  deleteWorkExperience = "/user/delete-work-experience-detail-by-pk/";
  addEducationDetails = "/user/create-education-info/";
  updateEducationInfo = "/user/update-education-info-by-pk/";
  deleteEducationInfo = "/user/delete-education-detail-by-pk/";
  addOrganizationDetails = "/user/create-organization/";
  updateOrganizationInfo = "/user/update-organization-by-pk/";
  deleteOrganizationInfo = "/user/delete-organization-by-pk/";
  connectionCount = "/connection/total-count/";
  getConnections = "/connection/get-friend-suggestion-list/";
  getJobsList = "/jobs/list-with-mobile-pagination/";
  getJobsListRegionBased = "/jobs/list-on-region-basis-with-mobile-pagination/";
  jobCount = "/jobs/get-job-leads/";
  getParticipation = "/participants/participation-for-you-pagination-list/";
  getRecievedParticipation =
    "/participants/get-all-participation-history-by-token/";
  getParticipants = "/participants/get-participants-mobile-pagination-list/";
  getFollowing = "/connection/following-list/";
  getFollowers = "/connection/followers-list/";
  helpCenter = "/help-center/send-mail/";
  getPending = "/connection/get-pending-list/";
  sendRequest = "/connection/send-request/";
  acceptRequest = "/connection/accept-request/";
  rejectRequest = "/connection/reject-request/";
  searchList = "/search/get-list-with-pagination/";
  recentSearch = "/search/get-recent-search-list/";
  searchDetails = "/search/get-details/";
  saveJob = "/jobs/save-job/";
  savedJobList = "/jobs/saved-jobs-pagination-list/";
  saveFeed = "/feed/save/";
  savedFeedList = "/feed/get-saved-feeds/";
  applyJob = "/jobs/apply-for-job/";
  blockUser = "/connection/block-user/";
  unBlockUser = "/connection/unblock-user/";
  blockedUserList = "/connection/block-user-list/";
  getInboxlistwithPagination = "/message/inobx-pagination-listing/";
  sentMsgwithPagination = "/messsage/sent-message-pagination-list/";
  getTrashMsgwithPagination = "/message/trash-message-paginaiton-list/";
  getDraftMsgwithPagination = "/message/darft-message-pagination-list/";
  deleteTrashMessage = "/message/delete-trash-message/";
  deleteSentMessage = "/message/delete-sent-message/";
  moveToTash = "/message/move-to-trash/";
  updateEmailandPhone = "/user/update-email-phone/";
  changePassword = "/user/change-password/";
  getAllFriends = "/connection/get-all-friend-list/";
  sendMessage = "/message/send-message/";
  notificationSetting = "/user/notification-settings-list/";
  clearSearchHistory = "/search/clear-search-history/";
  getConversationList = "/message/conversation-pagination-list/";
  sendDraftMessage = "/message/send-draft-message/";
  getAllTrashMessages = "/message/get-all-trash-message/";
  deleteDraftMessage = "/message/delete-draft-message/";
  reachOutToSell = "/participants/reach-to-sell/";
  reachOutToBuy = "/participants/reach-to-buy/";
  getParticipationDetails = "/participants/get-details-by-id/";
  getParticipantsList =
    "/participants/get-participants-mobile-pagination-list/";
  getParticipantsForYou =
    "/participants/participation-for-you-pagination-list/";
  updateResume = "/user/update-resume/";
  getBusinessJobDettails = "/jobs/get-details-by-id/";
  getAllJobs = "/jobs/get-list-mobile-pagination-by-token/";
  createJobs = "/jobs/create/";
  updateJobs = "/jobs/update/";
  getJobLeads = "/jobs/get-job-leads-list/";
  getJobLeadsByName = "/jobs/get-job-list-by-lead-name/";
  rssFeed = "/rss/get-rss-feed-list/";
  updateFactorAuth = "/user/update-factor-auth-check/";
  varifyFactoryOtp = "/user/varify-factory-otp/";
  resendTwoFactorOtp = "/user/resend-two-factor-otp/";
  feedDetailsById = "/feed/get-details/";
  followersListByUser = "/connection/followers-list-by-user/";
  followingListByUser = "/connection/following-list-by-user/";
  updateSectorReason = "/user/update-sector-reason/";
  getAllEvents = "/calander/get-all-events/";
  fredApi = "/fred/get-all-data/";
  restoreMessage = "/message/restore-trash-message/";
  unreadMsgsCount = "/message/get-all-unread-messages-count/";
  searchConnections = "/search/get-connection-list-with-pagination/";
  getGroupChatList = "/group/get-group-pagination-list/";
  createGroup = "/group/create/";
  getGroupMemberList = "/group/get-group-members-list/";
  addGroupMember = "/group/add-group-member/";
  changeMemberRole = "/group/change-member-role/";
  leaveGroup = "/group/leave-group/";
  removeFromGroup = "/group/remove-from-group/";
  deleteGroup = "/group/delete-group/";
  getGroupDetailsById = "/group/get-details-by-id/";
  deleteGroupMessage = "/group/delete-group-message/";
  friendListForgroup = "/group/friend-list-for-group/";
  updateGroupIcon = "/group/update-group-icon/";
  updateGroupName = "/group/update-group-name/";
  reportGroup = "/group/report-group/";
  userFollowing = "/connection/following-list-by-user/";
  userFollowers = "/connection/followers-list-by-user/";
  deleteJob = "/jobs/delete/";
  unApplyJob = "/jobs/unapply-job/";
  getLoanTypeList = "/participants/get-loan-type-list";
  getLoanPerformingList = "/participants/get-loan-performing-list";
  getLoanCollateralTypeList = "/participants/get-loan-collateral-type-list";
  getCommercialLoanList = "/participants/get-commercial-loan-list/";
  logout = "/auth/logout/";
  getSameRegionSuggetion = "/connection/get-same-region-suggestion-list/";
  getFriendWorkedList = "/connection/get-friend-worked-list/";
  getJobApplicantList = "/jobs/get-applicant-bussiness-pagination-list/";
  recievedParticipation = "/participants/received-participation/";
  sentParticipation = "/participants/sent-participation/";
  deleteParticipation = "/participants/delete-participation/";
  deleteSentParticipation = "/participants/delete-sent-loan/";
  aboutUs = "/user/get-cms-details/";
  feedReport = "/feed/report/";
  closeAccount = "/user/update-user-profile-status/";
  leadsLoanlist = "/participants/lead-page-loan-list/";
  leadsCommercialcount = "/participants/get-user-commercial-loan-list/";
  leadPageActivity = "/participants/lead-page-activity/";
  isLike = "/participations/is-like/";
  leadPageDetailList = "/participants/lead-page-detail-list/";
  leadPageDetailParti = "/participants/lead-page-detail-by-participanst/";
  deleteLead = "/participants/delete-leads/";
}
