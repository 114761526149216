import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { apiList } from "./api-list";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpsService {
  baseUrl: string = environment.backendUrl;
  public seachSubject: Subject<string> = new Subject();
  public selectorInput: Subject<boolean> = new Subject();
  public clearSearch: Subject<boolean> = new Subject();
  public profileUpdate: Subject<boolean> = new Subject();
  public countTimer: any;

  constructor(private http: HttpClient, private apiList: apiList) {}

  // Http Post

  httpPost(url: string, params): Observable<Object> {
    return this.http.post(this.baseUrl + this.apiList[url], params);
  }

  httpPostWithFormDataHeader(url: string, params): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      params,
      this.formDataHeader()
    );
  }

  httpPostWithHeader(url: string, params: any): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      params,
      this.header()
    );
  }

  httpPostWithHeaderAndById(url: string, id, params: any): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url] + id + "/",
      params,
      this.header()
    );
  }

  // --------

  // Http Put

  httpPutWithFormDataHeaderAndId(
    url: string,
    id: any,
    param
  ): Observable<Object> {
    return this.http.put(
      this.baseUrl + this.apiList[url] + id + "/",
      param,
      this.formDataHeader()
    );
  }

  httpPutWithBodyHeaderAndId(url: string, id: any, param): Observable<Object> {
    return this.http.put(
      this.baseUrl + this.apiList[url] + id + "/",
      param,
      this.header()
    );
  }

  httpPutWithBodyAndHeader(url: string, param): Observable<Object> {
    return this.http.put(
      this.baseUrl + this.apiList[url],
      param,
      this.header()
    );
  }

  httpPutWithFormDataWithoutHeaderById(
    url: string,
    id: any,
    param
  ): Observable<Object> {
    return this.http.put(this.baseUrl + this.apiList[url] + id + "/", param);
  }

  // --------

  // Http Get

  httpGet(url: string): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url]);
  }

  httpGetWithoutHeaderById(url: string, params): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url] + params + "/");
  }

  httpGetById(url: string, id): Observable<Object> {
    return this.http.get(
      this.baseUrl + this.apiList[url] + id + "/",
      this.header()
    );
  }

  httpGetByIdAndType(url: string, id: any, type: any): Observable<Object> {
    return this.http.get(
      this.baseUrl + this.apiList[url] + id + "/" + type + "/",
      this.header()
    );
  }

  httpGetWithQParam(url: string, params): Observable<Object> {
    return this.http.get(
      this.baseUrl + this.apiList[url] + "?" + params,
      this.header()
    );
  }

  httpGetWithHeaderOnly(url: string): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url], this.header());
  }

  // --------

  // Https Delete

  httpDeleteWithHeaderById(url: string, id): Observable<Object> {
    console.log("delete event", id + "/");
    return this.http.delete(
      this.baseUrl + this.apiList[url] + id + "/",
      this.header()
    );
  }

  httpDeleteWithHeaderBody(url, param): Observable<Object> {
    const headers = new HttpHeaders({
      "content-type": "application/json",
      Authorization: "Bearer " + localStorage.bancreach_Authorization,
    });
    const options = {
      headers: new HttpHeaders({
        "content-type": "application/json",
        Authorization: "Bearer " + localStorage.bancreach_Authorization,
      }),
      body: param,
    };
    return this.http.delete(this.baseUrl + this.apiList[url], options);
  }

  httpDeleteWithHeaderOnly(url: string): Observable<Object> {
    return this.http.delete(this.baseUrl + this.apiList[url], this.header());
  }

  // --------

  uploadMedia(url, param: any) {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      param,
      this.formDataHeader()
    );
  }

  fileUpload(fileData: any) {
    var formData = new FormData();
    formData.append("image", fileData, fileData.name);
    const headers = new HttpHeaders({
      mimeType: "multipart/form-data",
      Authorization: "Bearer " + localStorage.bancreach_Authorization,
    });
    const headersObj = { headers };
    return this.http.post(
      this.baseUrl + this.apiList["uploadFile"],
      formData,
      headersObj
    );
  }

  userFileUploadWithoutAuth(fileData: any, formKey, apiUrl) {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append(formKey, fileData);
      const headers = new HttpHeaders({
        mimeType: "multipart/form-data",
        // Authorization: localStorage.bancreach_Authorization
      });
      const headersObj = { headers };
      this.http
        .post(this.baseUrl + this.apiList[apiUrl], formData, headersObj)
        .subscribe((res: any) => {
          if (res.statusCode == 200) {
            if (res.data.length > 0) {
              return resolve(res.data[0]);
            }
          }
        });
    });
  }

  import(fileData: any, formKey, apiUrl) {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append(formKey, fileData);
      const headers = new HttpHeaders({
        mimeType: "multipart/form-data",
        Authorization: "Bearer " + localStorage.bancreach_Authorization,
      });
      const headersObj = { headers };
      this.http
        .post(this.baseUrl + this.apiList[apiUrl], formData, headersObj)
        .subscribe((res: any) => {
          if (res.statusCode == 200) {
            return resolve(res.data);
          }
        });
    });
  }

  header() {
    if (
      localStorage.bancreach_Authorization != undefined ||
      localStorage.bancreach_Authorization != null
    ) {
      const headers = new HttpHeaders({
        "content-type": "application/json",
        Authorization: "Bearer " + localStorage.bancreach_Authorization,
        // "device-type":"WEB",
        // "device-token":localStorage.fcmToken
      });
      const option = {
        headers,
      };
      return option;
    }
  }

  formDataHeader() {
    if (
      localStorage.bancreach_Authorization != undefined ||
      localStorage.bancreach_Authorization != null
    ) {
      const headers = new HttpHeaders({
        Authorization: "Bearer " + localStorage.bancreach_Authorization,
        // "device-type":"WEB",
        // "device-token":localStorage.fcmToken,
      });
      const option = {
        headers,
      };
      return option;
    }
  }

  linkPreview(link: string) {
    return this.http.get(
      "https://api.linkpreview.net/?key=" +
        environment.linkPreviewKey +
        "&q=" +
        link
    );
  }
}
