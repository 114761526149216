import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { ModalService } from "../../modal/modal.service";
import { CommonService } from "../../services/common/common.service";
import { HttpsService } from "../../services/http/http.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-header-profile",
  templateUrl: "./header-profile.component.html",
  styleUrls: ["./header-profile.component.scss"],
})
export class HeaderProfileComponent implements OnInit {
  user: any;
  imgBaseUrl = environment.imageUrl;
  search: string = "";
  @Input() isSearchbox: boolean = false;
  searchData: any[];

  constructor(
    private http: HttpsService,
    public commonServcie: CommonService,
    private modalService: ModalService,
    private router: Router
  ) {
    this.http.clearSearch.subscribe((res) => {
      if (res == false) {
        this.search = "";
      }
    });
    this.user = JSON.parse(localStorage.getItem("bancreach_web_user"));
    this.http.profileUpdate.subscribe((res) => {
      if (res == true) {
        this.user = JSON.parse(localStorage.getItem("bancreach_web_user"));
      }
    });
  }

  ngOnInit() {
    localStorage.setItem("isStart", "false");
  }
  openNav() {
    document.getElementById("mySidenav").style.width = "230px";
  }

  closeNav() {
    document.getElementById("mySidenav").style.paddingRight = "0";
  }
  goToSearch() {
    localStorage.setItem("searchVal", this.search);
    this.http.seachSubject.next(this.search);
    this.router.navigate(["/search"]);
  }

  onKeyUp(event) {
    if (event.code == "Backspace") {
      if (event.target.value.length == 0) {
        return false;
      }
    }
    if (event.target.value.length >= 1) {
      if (
        this.search != "" ||
        this.search != null ||
        this.search != undefined
      ) {
        // this.getSearch(event.target.value);
      }
    }
  }

  getSearch(searchText) {
    this.searchData = [];
    let param = {
      search_value: searchText,
      start: 0,
      length: 100,
    };
    this.http.httpPostWithHeader("searchList", param).subscribe((res) => {
      if (res["code"] == 200) {
        // this.searchData = res["data"][1].connections;
      }
    });
  }

  logout() {
    this.commonServcie.httpPostWithHeader("logout", {}).subscribe((res) => {
      if (res["code"] == 200) {
        localStorage.clear();
        this.http.selectorInput.next(false);
        localStorage.setItem("isStart", "false");
        this.commonServcie.presentsToast("success", res["message"]);
        this.router.navigate(["/"]);
      }
    });
  }

  check(option) {
    var param = {
      id: option.id,
      type: option.search_type,
    };
    localStorage.setItem("searchData", JSON.stringify(param));
    if (option.search_type == 1) {
      this.router.navigate(["/user-details", option.id, option.search_type]);
    } else {
      this.router.navigate(["/bank-detail", option.id, option.search_type]);
    }
    this.http.seachSubject.next(this.search);
  }
}
