import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "../../services/common/common.service";
import { HttpsService } from "../../services/http/http.service";
import { ModalService } from "../modal.service";
import { MatDialogRef } from "@angular/material/dialog";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-post-view",
  templateUrl: "./post-view.component.html",
  styleUrls: ["./post-view.component.scss"],
})
export class PostViewComponent implements OnInit {
  dataParam: any;
  feed_details: any;
  currentPage = 1;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    focusOnSelect: true,
  };

  @ViewChild("createFile", { static: false }) createFile: ElementRef;
  @ViewChild("updateFile", { static: false }) updateFile: ElementRef;
  @ViewChild("commentFile", { static: false }) commentFile: ElementRef;

  reachOutText: string = "";
  file: File = null;
  imgBaseUrl = environment.imageUrl;
  medias: any[] = [];
  selectedFiles: any[] = [];
  files: any[] = [];
  feedList: any[] = [];
  start: number = 0;
  fileTab: string;
  user: any;
  isReachOut: boolean = false;
  beginPending: number = 1;

  length = 10;
  config: any = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
  };

  commData: any[] = [];
  commentList: any[] = [];
  commentText: string = "";
  totalcomments: number;
  noOfClicks: number = 0;

  replyText: string = "";
  isReply: boolean = false;
  replyData: any[];

  step: number = 0;
  editData: any;
  isUpdate: boolean = false;
  editreachOutText: string = "";

  shareText: string = "";
  isShare: boolean = false;
  shareData: any[];

  friensList: any[] = [];
  privateText: string = "";
  isFriends: boolean = false;
  totalFriends: number = 0;
  beginFriends: number = 0;

  typeOfService: string = "";

  indices: any = [];

  carouselOne = {
    grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
    slide: 1,
    speed: 400,
    interval: 10000,
    point: true,
    load: 2,
    touch: true,
    loop: true,
    custom: "banner",
  };

  tags: any[] = [];
  tagsNames: any[] = [];
  taggedPeople: any[] = [];
  peopleList: any[] = [];
  mentionIds: any[] = [];
  mentionConfig = {
    triggerChar: "@",
    allowSpace: true,
    labelKey: "name",
    mentionSelect: this.onMentionSelect,
  };
  like_Users: any = [];

  constructor(
    public dialog: MatDialogRef<PostViewComponent>,
    private http: HttpsService,
    public commonServcie: CommonService,
    private modalService: ModalService,
    private router: Router
  ) {}

  onMentionSelect(element) {
    return `@${element.name} `;
  }

  itemSelected(item) {
    this.tags.push({ user: item.id });
    // console.log(this.tags);
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("bancreach_web_user"));
    this.getFeedDetails();
    this.getCommentList(0);
    this.getAllFriendsList();
  }

  getAllFriendsList() {
    let param = {
      start: 0,
      length: 100,
    };
    this.http.httpPostWithHeader("getAllFriends", param).subscribe((res) => {
      if (res["code"] == 200) {
        this.isFriends = true;
        this.friensList = res["data"];
        this.friensList.forEach((res) => {
          res["isChecked"] = false;
        });
        this.totalFriends = res["recordsTotal"];
        this.friensList.map((val) => {
          this.peopleList.push({
            // name: val.first_name + " " + val.last_name + " ",
            name: val.user_name,
            id: val.id,
          });
        });
      } else if (res["code"] == 400) {
        this.isFriends = true;
        this.friensList = [];
      }
    });
  }

  getFeedDetails() {
    this.http
      .httpGetById("feedDetailsById", this.dataParam.id)
      .subscribe((res: any) => {
        if (res["code"] == 200) {
          localStorage.setItem("modelData", JSON.stringify(res.data));
          this.feed_details = res.data;
          console.log("model fedd detail", this.feed_details);
        }
      });
  }

  getCommentList(start) {
    let param = {
      start: start,
      length: 10,
    };
    this.http
      .httpPostWithHeaderAndById("getCommentList", this.dataParam.id, param)
      .subscribe((res) => {
        if (res["code"] == 200) {
          this.commentList = res["data"];
          this.totalcomments = res["recordsTotal"];
        } else {
          this.commentList = [];
          this.totalcomments = res["recordsTotal"];
        }
      });
  }

  seeMore() {
    this.noOfClicks++;
    let start = this.noOfClicks * 10;
    let param = {
      start: start,
      length: 10,
    };
    this.http
      .httpPostWithHeaderAndById(
        "getCommentList",
        this.feed_details["id"],
        param
      )
      .subscribe((res) => {
        if (res["code"] == 200) {
          if (res["data"]) {
            this.commentList = this.commentList.concat(res["data"]);
            this.totalcomments = res["recordsTotal"];
          }
        }
      });
  }

  likefeed(feedData) {
    if (feedData.id) {
      this.http.httpGetById("likefeed", feedData.id).subscribe((res) => {
        if (res["code"] == 200) {
          feedData["like_status"] = feedData["like_status"] == 1 ? 0 : 1;
          if (feedData["like_status"] == 1) {
            feedData["total_count"]["total_likes"] =
              feedData["total_count"]["total_likes"] + 1;
          } else {
            feedData["total_count"]["total_likes"] =
              feedData["total_count"]["total_likes"] - 1;
          }
          // this.getFeedListAfterEvent(this.currentPage * this.length);
        }
      });
    }
  }

  checkMsgType(obj) {
    this.typeOfService = obj;
    if (this.files.length > 0) {
      this.upload();
    } else {
      if (obj == "comment") {
        this.addComments(obj);
      }
    }
  }

  onSelectFiles(event, type) {
    this.fileTab = type;
    this.medias = [];
    this.selectedFiles = [];
    Array.from(event.target.files).forEach((element) => {
      if (this.files.length < 4) {
        this.files.push(element);
      } else {
        this.commonServcie.presentsToast(
          "error",
          "You can not select more than 4 files"
        );
        return false;
      }
    });
    if (this.fileTab == "createFile") {
      this.createFile.nativeElement.value = "";
    } else if (this.fileTab == "updateFile") {
      this.updateFile.nativeElement.value = "";
    } else if (this.fileTab == "commentFile") {
      this.commentFile.nativeElement.value = "";
    }
    Array.prototype.forEach.call(this.files, (child) => {
      let check = this.commonServcie.validateImageAndVideoFile(child);
      if (check) {
        var reader = new FileReader();
        reader.readAsDataURL(child);
        reader.onload = (event) => {
          if (this.selectedFiles.length > 4) {
            this.createFile.nativeElement.value = "";
            this.commonServcie.presentsToast(
              "error",
              "You can not select more than 4 files"
            );
            return false;
          }
          let obj = {};
          obj["url"] = reader.result as string;
          obj["type"] = this.commonServcie.checkType0(reader.result as string);
          this.selectedFiles.push(obj);
        };
      } else {
        this.selectedFiles = [];
        this.files = [];
      }
    });
  }

  upload() {
    let formData: FormData = new FormData();
    for (let i = 0; i < this.files.length; i++) {
      formData.append("media", this.files[i]);
    }
    this.http.uploadMedia("uploadMedia", formData).subscribe((res: any) => {
      if (res.code == 200) {
        res.data.forEach((element) => {
          this.medias.push(element);
        });
        if (this.medias.length > 0) {
          if (this.typeOfService == "comment") {
            this.addComments(this.typeOfService);
          }
        }
        this.selectedFiles = [];
        this.files = [];
        this.createFile.nativeElement.value = "";
      }
    });
  }

  removeFile(idx) {
    this.selectedFiles.splice(idx, 1);
    this.files.splice(idx, 1);
  }

  addComments(type) {
    let mediaIds: Array<number>;
    mediaIds = [];
    this.medias.forEach((element) => {
      mediaIds.push(element.id);
    });

    var text;

    if (type == "comment") {
      text = this.commentText;
    } else {
      text = this.replyText;
    }

    var tempTags: any[] = [];
    tempTags = this.tags;
    this.tags = [];
    var tempText = this.commentText.toString().trim().split("@");
    tempText.forEach((element) => {
      if (element) {
        this.peopleList.map((val) => {
          if (element.toString().trim().search(val.name.trim()) !== -1) {
            var arr = tempTags.map((val) => val.user);
            if (arr.includes(val.id)) {
              this.tags.push({ user: val.id });
            }
          }
        });
      }
    });

    let param;

    if (!this.isReply && type == "comment") {
      param = $.extend(
        {},
        {
          comment_tags: this.tags.length != 0 ? this.tags : [],
          content: this.commentText,
          feed: this.feed_details["id"],
          comment_media: mediaIds,
          parent_id: null,
        }
      );
    } else {
      param = $.extend(
        {},
        {
          comment_tags: this.tags.length != 0 ? this.tags : [],
          content: this.replyText,
          feed: this.feed_details["id"],
          comment_media: mediaIds,
          parent_id: this.replyData["id"],
        }
      );
    }
    // console.log(param)
    // return false;
    this.http.httpPostWithHeader("createComment", param).subscribe((res) => {
      if (res["code"] == 200) {
        this.resetFields();
        this.getCommentList(0);
        this.getFeedDetails();
      }
    });
  }

  replyComment(data) {
    this.commentText = "";
    this.isReply = !this.isReply;
    if (this.isReply) {
      this.replyText = "";
      this.medias = [];
      this.replyData = data;
    }
  }

  deleteComment(id) {
    this.http.httpDeleteWithHeaderById("deleteComment", id).subscribe((res) => {
      if (res["code"] == 200) {
        this.getCommentList(0);
        this.getFeedDetails();
      }
    });
  }

  resetFields() {
    this.reachOutText = "";
    this.editreachOutText = "";
    this.commentText = "";
    this.replyText = "";
    this.isReply = false;
    this.medias = [];
    this.shareText = "";
    this.privateText = "";
    this.beginFriends = 0;
    this.isReachOut = false;
    this.tags = [];
    this.tagsNames = [];
    this.friensList.forEach((res) => {
      res["isChecked"] = false;
    });
    $("#popUp").removeClass("popup-blur");
    $("#blurIt0").removeClass("blur-bg");
    $("#blurIt").removeClass("blur-bg");
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("stop-scroll");
    this.tags = [];
    this.peopleList = [];
    this.friensList.map((val) => {
      this.peopleList.push({
        // name: val.first_name + " " + val.last_name + " ",
        name: val.user_name,
        id: val.id,
      });
    });
  }

  imagePreview(data) {
    this.modalService.imgaePreview(data).subscribe((res: any) => {
      if (res == "close") {
      }
    });
  }

  onCancel(val) {
    this.dialog.close(val);
  }

  onProcced() {
    this.dialog.close("yes");
  }

  goToProfile(data) {
    this.onCancel("close");
    if (data.id == this.user["id"]) {
      this.router.navigate(["/edit-profile"]);
    } else {
      this.router.navigate(["/user-details", data.id, 1]);
    }
  }

  changeUrl(id) {
    // console.log(id);
    // console.log(this.user.id)
    if (this.user.id == id) {
      this.router.navigate(["/edit-profile"]);
    } else {
      this.router.navigate(["/user-details", id, 1]);
    }
  }

  Next() {
    this.step = 0;
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("scroll-hide");
  }

  tagPeople(event: any, user: any) {
    if (event.checked) {
      user.isChecked = true;
      this.tags.push({
        user: user.id,
      });
      this.tagsNames.push({
        user: user.first_name + " " + user.last_name + " ",
      });
    } else {
      user.isChecked = false;
      let index = this.tags.findIndex((tag) => tag.user == user.id);
      this.tags.splice(index, 1);
      this.tagsNames.splice(index, 1);
    }
  }

  saveFeed(feedData) {
    this.http.httpGetById("saveFeed", feedData.id).subscribe((res) => {
      if (res["code"] == 200) {
        feedData["is_saved"] = feedData["is_saved"] == 1 ? 0 : 1;
        this.commonServcie.presentsToast("success", res["message"]);
      }
    });
  }

  closeModal() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("scroll-hide");

    this.step = 0;
  }

  getLike_Users() {
    if (this.feed_details.like_users.length != 0) {
      this.goToNext(9);
      this.like_Users = this.feed_details.like_users;
    }
  }

  goToNext(next) {
    this.step = next;
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("scroll-hide");
  }
}
