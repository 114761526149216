import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkifyPipe } from '../services/pipes/linkify.pipe';
import { MentionModule } from 'angular-mentions-v2';
import { ImageCropperModule } from 'ngx-image-cropper'

@NgModule({
  declarations: [
    LinkifyPipe
  ],
  exports: [
    LinkifyPipe,
    MentionModule,
    ImageCropperModule
  ],
  imports: [
    CommonModule,
    MentionModule,
    ImageCropperModule
  ]
})
export class SharedModule { }
