import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { CommonService } from '../../services/common/common.service';
import { HttpsService } from '../../services/http/http.service';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss']
})
export class ReportModalComponent implements OnInit {
  message: any;
  page=0;
  reason='';
  reasonTwo=''
  constructor(
    public dialog: MatDialogRef<ReportModalComponent>,
    private http: HttpsService,
    public commonServcie: CommonService,
    private modalService: ModalService,
  ) { }
  
  feed1=["I'm not interested in this Feed",
  "It's suspicious or spam",
  "It display a sensitive photo or video",
  "It's abusive or harmful",
  "It's misleading",
  "It expresses intentions of self-harmor suicide"
]

feed2=["The account Created feed is fake",
  "Includes a link to a potentianlly harmful,malicious or phishing site",
  "Uses the reply function to spam",
  "It's something else"
]
feed3=["Adult",
  "Violent",
  "Hateful",
  "An unauthorized photo or video"
]

feed4=["It's disrespectful or offensive",
  "Includes private information",
  "Includes targeted harassment",
  "It directs hate against a protected category (e.g.,race, religion, gender, orientation, disability)",
  "Threating violence or physical harm",
  "They're encouraging self-harm or suicide"
]

  ngOnInit() {
    console.log(this.message);
  }

  onCancel() {
    this.dialog.close();
  }
  
  changePage(index,item){
  if(index==0 || index==4 || index==5){
    this.page=1;
    this.reason=item
  }else{
    if(index==10){
    this.page=1;
    this.reasonTwo=item;
    return
    }
    this.page=index+1;
  }
  console.log(item);
  }

  blockReport() {
    let body:any={}
    if(this.message.id){
      body={
        "message": this.reason,
        "second_reason": this.reasonTwo,
        "user": this.message.id
      }
    }else{
      body={
        "feed": this.message.id,
        "message": this.reason,
        "second_reason": this.reasonTwo,
        "user": this.message.created_by.id}
    }
    this.http.httpPostWithHeader("feedReport", body).subscribe((res) => {
      if (res["code"] == 200) {
        this.commonServcie.presentsToast("success", res["message"]);
        this.dialog.close('close');
      }
    });
  }

}
