import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "linkify",
})
export class LinkifyPipe implements PipeTransform {
  constructor(private _domSanitizer: DomSanitizer) {}

  transform(value: any, args?: any): any {
    return this._domSanitizer.bypassSecurityTrustHtml(this.stylize(value));
  }

  private stylize(text: string): string {
    let stylizedText: string = "";
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    if (text)
      return text.replace(urlRegex, function (url) {
        url && (text = text.replace(url, ""));
        if (text && text.length > 0) {
          for (let t of text.split(" ")) {
            if (t.startsWith("@") && t.length > 1)
              stylizedText += `<span style="color:#5890c4">${t}</span> `;
            else stylizedText += t + " ";
          }
          return stylizedText;
        } else return text;
      });
    if (!text) return "";
  }

  urlify(text: string): string {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      url && (text = text.replace(url, ""));
      return text;
    });
  }
}
