import { getLocaleDayNames } from "@angular/common";

export class Constant {
  COMPLAIN_STATUS: any = {
    PENDING: 0,
    IN_PROCESS: 1,
    REJECTED: 2,
    ACCEPTED: 3
  };
  SHOW_COMPLAIN_STATUS: any = {
    0: 'PENDING',
    1: 'IN_PROCESS',
    2: 'REJECTED',
    3: 'ACCEPTED'
  };
  DEVICE_TYPE: any = {
    ANDROID: 0,
    IOS: 1,
    WEB: 2
  };
  PACKAGE: any = {
    DURATIONTYPE: {
      DAY: 1,
      MONTH: 2,
      YEAR: 3
    }
  };
  GET_DURATIONTYPE: any = {
    1: 'DAY',
    2: 'MONTH',
    3: 'YEAR'
  };
  CMS: any = {
    CONTACT_SUPPORT:1,
    LEGAL:2,
    PRIVACY_POLICY:3,
    FAQS:4,
    OUR_MISSION:5,
    EASY_TO_USE:6,
    MEMBERSHIP:7,
    FINCO_APP:8,
    WHERE_DOES_IT_COME_FROM:9,
    GET_START:10,
    OUR_PRICING_PLANS:11
  };
  IMPORT_FILE_TYPE: any = {
    EXCEL: 0,
    CSV:1
  };
}