import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { MaterialModule } from "./bancreachwebsite/material/material.module";
import { LightboxModule } from "ngx-lightbox";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { apiList } from "./bancreachwebsite/services/http/api-list";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { Constant } from "./bancreachwebsite/core/constant";
import { HttpInterceptorService } from "./bancreachwebsite/services/interceptor/http-interceptor.service";
import {
  NgxUiLoaderConfig,
  NgxUiLoaderHttpModule,
  NgxUiLoaderModule,
  NgxUiLoaderService,
} from "ngx-ui-loader";
import { NgxPaginationModule } from "ngx-pagination";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { SlickCarouselModule } from "ngx-slick-carousel";
// Modal Components

import { ConfirmationModalComponent } from "./bancreachwebsite/modal/confirmation-modal/confirmation-modal.component";
import { ImagePreviewComponent } from "./bancreachwebsite/modal/image-preview/image-preview.component";
import { ImageCroppingComponent } from "./bancreachwebsite/modal/image-cropping/image-cropping.component";
import { MessagesService } from "./bancreachwebsite/pages/messages/messages.service";
import { PinchZoomModule } from "ngx-pinch-zoom";
import { PostViewComponent } from "./bancreachwebsite/modal/post-view/post-view.component";
import { SharedModule } from "./bancreachwebsite/shared/shared.module";
import { MentionModule } from "angular-mentions-v2";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from '../environments/environment';
import { ReportModalComponent } from "./bancreachwebsite/modal/report-modal/report-modal.component";
import { MarketUserComponent } from "./bancreachwebsite/modal/market-user/market-user.component";
import { HelpCenterComponent } from "./bancreachwebsite/modal/help-center/help-center.component";
// import { AgmCoreModule } from "@agm/core";
// import { environment } from 'src/environments/environment';
// import { LinkifyPipe } from './bancreachwebsite/services/pipes/linkify.pipe';

// --------

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "blue",
  // bgsOpacity: 0.5,
  // bgsPosition: POSITION.bottomLeft,
  // bgsSize: 60,
  // bgsType: SPINNER.chasingDots,
  // blur: 5,
  // delay: 0,
  fastFadeOut: true,
  fgsColor: "blue",
  // fgsPosition: POSITION.centerCenter,
  // fgsSize: 60,
  // fgsType: SPINNER.chasingDots,
  // gap: 24,
  // logoPosition: POSITION.centerCenter,
  // logoSize: 120,
  // logoUrl: 'assets/angular.png',
  // overlayBorderRadius: '0',
  // overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: "blue",
  // pbDirection: PB_DIRECTION.leftToRight,
  // pbThickness: 5,
  // hasProgressBar: false,
  // text: 'Welcome to ngx-ui-loader',
  // textColor: '#FFFFFF',
  // textPosition: POSITION.centerCenter,
  // maxTime: -1,
  // minTime: 500
};

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationModalComponent,
    ImagePreviewComponent,
    PostViewComponent,
    ImageCroppingComponent,
    ReportModalComponent,
    MarketUserComponent,
    HelpCenterComponent
    // LinkifyPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    FormsModule,
    CommonModule,
    NgxUiLoaderModule,
    NgxPaginationModule,
    GooglePlaceModule,
    SlickCarouselModule,
    LightboxModule,
    PinchZoomModule,
    SharedModule,
    MentionModule,
    ServiceWorkerModule.register("/ngsw-worker.js", {
      scope: "/firebase-cloud-messaging-push-scope",
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // AgmCoreModule.forRoot({
    //   apiKey: environment.googleApiKey,
    //   libraries: ["drawing", "places", "geometry"],
    // }),
    // NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    // NgxUiLoaderHttpModule,
    // NgxUiLoaderHttpModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    ConfirmationModalComponent,
    ImagePreviewComponent,
    PostViewComponent,
    ImageCroppingComponent,
    ReportModalComponent,
    MarketUserComponent,
    HelpCenterComponent
  ],
  providers: [
    MessagesService,
    NgxUiLoaderService,
    apiList,
    Constant,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
