import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { CommonService } from '../../services/common/common.service';
import { HttpsService } from '../../services/http/http.service';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent implements OnInit {
  isSubmitted:boolean=false
  message: string;
  helpCenter:FormGroup;
  constructor(public dialog: MatDialogRef<HelpCenterComponent>,
    private fb:FormBuilder,
    private http:HttpsService,
    private commonService:CommonService) { }

  ngOnInit() {
    this.helpCenter=this.fb.group({
      name:['',[Validators.required]],
      email:['',[Validators.required,Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),]],
      comments:['',[Validators.required]]
    })
  }

  

  onProcced() {
    this.isSubmitted=true;
    if(this.helpCenter.invalid){
      this.helpCenter.markAllAsTouched();
      return;
    }
    this.http.httpPostWithHeader("helpCenter",this.helpCenter.value).subscribe(res=>{
      this.commonService.presentsToast("success", res["message"]);
      this.dialog.close('yes');
    })
  }
  
  
  get fval() {
    return this.helpCenter.controls;
  }

}
