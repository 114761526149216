import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  forkJoin,
  interval,
  Observable,
  Subject,
  timer,
} from "rxjs";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";
import { mergeMap, timeInterval } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { apiList } from "../http/api-list";
@Injectable({
  providedIn: "root",
})
export class CommonService {
  user: any;
  imgBaseUrl = environment.imageUrl;
  showSlider: boolean = true;
  showLoaderSnackBar: boolean = true;
  baseUrl: string = environment.backendUrl;
  constructor(private http: HttpClient,private apiList: apiList) {
    this.user = JSON.parse(localStorage.getItem("bancreach_web_user"));
  }

  presentsToast(type: any, message: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      timerProgressBar: true,
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      timer: 3000,
    });
    Toast.fire({
      title: message,
      icon: type,
    });
  }

  openDialog(type, message) {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: type,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
    });
  }

  validateImageFile(fileData) {
    if (
      fileData.type == "image/jpeg" ||
      fileData.type == "image/png" ||
      fileData.type == "image/jpg" ||
      fileData.type == "image/svg" ||
      fileData.type == "image/webp" ||
      fileData.type == "image/gif"
    ) {
      return true;
    } else {
      this.presentsToast("error", "Please upload a valid image file!");
      return false;
    }
  }

  validateImageAndVideoFile(fileData) {
    if (
      fileData.type.includes("image") ||
      fileData.type.includes("video") ||
      fileData.type.includes("application") ||
      fileData.type.includes("text")
    ) {
      return true;
    } else {
      this.presentsToast("error", "This type of file is not allowed!");
      return false;
    }
  }

  validateDocumentFiles(fileData) {
    if (
      fileData.type.includes("application") ||
      fileData.type.includes("text")
    ) {
      return true;
    } else {
      this.presentsToast("error", "This type of file is not allowed!");
      return false;
    }
  }

  checkType0(file_type) {
    if (!(file_type == "" || file_type == null || file_type == undefined)) {
      let split0 = file_type.split("data:");
      let split1 = split0[1].split("/");
      return split1[0];
    }
  }

  checkType(file_type) {
    if (!(file_type == "" || file_type == null || file_type == undefined)) {
      let split = file_type.split("/");
      return split[0];
    }
  }

  profileImg() {
    let imgUrl;
    if (this.user.profile_image_url) {
      imgUrl = this.user.profile_image_url;
    } else {
      imgUrl = "assets/img/no_img.png";
    }
    return imgUrl;
  }

  getSymbol() {
    return timer(0, 10000).pipe(
      mergeMap(() =>
        forkJoin([
          // (this.showLoaderSnackBar = false),
          // this.http.get("https://cnbc.p.rapidapi.com/market/list-indices", {
          //   headers: this.setHeaders(),
          // }),

          // this.http.get('https://cnbc.p.rapidapi.com/news/v2/list-by-symbol?symbol=AAPL&page=1&pageSize=20',{
          // 	headers : this.setHeaders()
          // })
        ])
      )
    );
  }

  setHeaders() {
    return {
      "x-rapidapi-key": "4964dc9c94msh51aefbd9ead62c3p1a03adjsn3442c65d3ee8",
      "x-rapidapi-host": "cnbc.p.rapidapi.com",
    };
  }

  noSpace(event) {
    if (event.which === 32 && !event.target.value.length)
      event.preventDefault();
  }

  
  httpPostWithHeader(url: string, params: any): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      params,
      this.header()
    );
  }

  httpPostWithFormDataHeader(url: string, params): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      params,
      this.formDataHeader()
    );
  }
  
  header() {
    if (
      localStorage.bancreach_Authorization != undefined ||
      localStorage.bancreach_Authorization != null
    ) {
      const headers = new HttpHeaders({
        "content-type": "application/json",
        Authorization: "Bearer " + localStorage.bancreach_Authorization,
        // "device-type":"WEB",
        // "device-token":localStorage.fcmToken || ""
      });
      const option = {
        headers,
      };
      return option;
    }
  }

  

  formDataHeader() {
    if (
      localStorage.bancreach_Authorization != undefined ||
      localStorage.bancreach_Authorization != null
    ) {
      const headers = new HttpHeaders({
        Authorization: "Bearer " + localStorage.bancreach_Authorization,
        // "device-type":"WEB",
        // "device-token":localStorage.fcmToken || "",
      });
      const option = {
        headers,
      };
      return option;
    }
  }

  getLink(content: string): boolean {
    var expression =
      "^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";
    var regex = new RegExp(expression);
    console.log(content);
    console.log(content.match(regex));

    if (content.match(regex)) {
      return true;
    } else {
      return false;
    }
  }

  
}
