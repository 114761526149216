import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, retry, tap } from "rxjs/operators";
import { CommonService } from "../common/common.service";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { HttpsService } from "../http/http.service";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  private token: string;
  totalRequests: number = 0;
  showSnackBar: boolean = true;

  constructor(
    private commonbService: CommonService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private http: HttpsService,
    private _snackBar: MatSnackBar
  ) {
    // this.openSnackBar();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      // req.url === "https://cnbc.p.rapidapi.com/market/list-indices" ||
      // req.url ===
      //   "https://api.bancreach.com/api/message/get-all-unread-messages-count/" ||
      // req.url ===
      //   "https://api.bancreach.com/api/user/notification-count/",
      req.url ===
        "http://python.webdevelopmentsolution.net:8888/api/user/notification-count/" ||
      req.url ===
        "http://python.webdevelopmentsolution.net:8888/api/message/get-all-unread-messages-count/"
    ) {
      this.showSnackBar = false;
    } else {
      this.showSnackBar = true;
      this.totalRequests++;
      console.log(this.totalRequests);
      if (this.showSnackBar && !["search/get-details/"].includes(req.url)) {
        this.openSnackBar();
      }
    }
    if (
      req.url.split("api/")[1] != "user/notification-count/" &&
      req.url.split("api/")[1] != "message/get-all-unread-messages-count/" &&
      req.url.split("api/")[1] != "user/check-profile-status/" &&
      req.url.split("rapidapi")[0] != "https://cnbc.p." &&
      !req.url.split("api")[1].includes("check-profile-status")
      // && ["search/get-details/"].includes(req.url)
    ) {
      this.ngxService.start();
    }
    return next.handle(req).pipe(
      // retry(2),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          // 401 handled in auth.interceptor
          // this.commonbService.presentsToast('error','top-end','you are logout for security purpose.');
        }
        if (error.status == 0) {
          clearInterval(this.http.countTimer);
          this.commonbService.openDialog(
            "error",
            "Oops!... Something went wrong."
          );
          this.ngxService.stop();
          return;
        }
        return throwError(error);
      }),
      finalize(() => {
        this.ngxService.stop();
        if (
          //   req.url === "https://cnbc.p.rapidapi.com/market/list-indices" ||
          //   req.url ===
          //   "https://api.bancreach.com/api/message/get-all-unread-messages-count/" ||
          // req.url ===
          //   "https://api.bancreach.com/api/user/notification-count/",
          req.url ===
            "http://python.webdevelopmentsolution.net:8888/api/message/get-all-unread-messages-count/" ||
          req.url ===
            "http://python.webdevelopmentsolution.net:8888/api/user/notification-count/"
        ) {
          this.totalRequests = this.totalRequests;
        } else {
          this.totalRequests--;
          console.log(this.totalRequests);
        }
        if (this.totalRequests === 0) {
          this.showSnackBar = true;
          this._snackBar.dismiss();
        }
      }),
      tap(
        (event) => {
          var eventRes = JSON.parse(JSON.stringify(event));
          if (eventRes.body) {
            if (
              eventRes.body.statusCode === 400 ||
              eventRes.body.code === 400
            ) {
              if (eventRes.body.error) {
                // this.commonbService.presentsToast("error", eventRes.body.error);
              } else if (eventRes.body.message) {
                // this.commonbService.presentsToast(
                //   "error",
                //   eventRes.body.message
                // );
              }
            }
          }
        },
        (error) => {
          switch (error.status) {
            case 401:
              /***  Auto LogOut if Api response 401 ** */
              this.commonbService.presentsToast(
                "warning",
                "You have been loggedOut for security purpose."
              );
              this.http.selectorInput.next(false);
              localStorage.clear();
              this.router.navigate([`/`]);
              break;
            case 500:
              /*** If api does not respond  ** */
              this.commonbService.presentsToast("warning", "Api Not Working");
              break;
            default:
              if (error.error) {
                error.error.message &&
                  this.commonbService.presentsToast(
                    "error",
                    error.error.message
                  );
              } else {
                error.message &&
                  this.commonbService.presentsToast("error", error.message);
              }
          }
        }
      )
    );
  }

  openSnackBar() {
    let config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.horizontalPosition = "center";
    (config.panelClass = ["my-loader-snackbar"]),
      this._snackBar.open("Loading...", "", config);
  }
}
