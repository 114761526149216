import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit {

  album: any[] = [];
  productIndex:any;
  slideConfigImage: any;
  slideConfigImageRtl: any;

  constructor(public dialog: MatDialogRef<ImagePreviewComponent>) { 
    }

  ngOnInit(): void {
    this.slideConfigImage = {
      "slidesToShow": 1,
      "slidesToScroll": 1,
      dots: false,
      autoplay: true,
      arrow: false,
      "initialSlide": this.productIndex,
      responsive: [
        {
          'breakpoint': 767,
          'settings': {
            'slidesToShow': 1
          }
        },
        {
          'breakpoint': 576,
          'settings': {
            'slidesToShow': 1
          }
        },
        {
          'breakpoint': 400,
          'settings': {
            'slidesToShow': 1
          }
        },
        {
          'breakpoint': 300,
          'settings': {
            'slidesToShow': 1
          }
        }
      ]
    };
    this.slideConfigImageRtl = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    dots: true,
    arrow: false,
    autoplay: true,
    rtl: true,
    "initialSlide": this.productIndex,
    responsive: [
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 1
        }
      },
      {
        'breakpoint': 576,
        'settings': {
          'slidesToShow': 1
        }
      },
      {
        'breakpoint': 400,
        'settings': {
          'slidesToShow': 1
        }
      },
      {
        'breakpoint': 300,
        'settings': {
          'slidesToShow': 1
        }
      }
    ]
  };
  }

  onCancel() {
    this.dialog.close('close');
  }

  onProcced() {
    this.dialog.close('yes');
  }

}
