import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-market-user',
  templateUrl: './market-user.component.html',
  styleUrls: ['./market-user.component.scss']
})
export class MarketUserComponent implements OnInit {
  
  message: string;

  constructor(public dialog: MatDialogRef<MarketUserComponent>) { }

  ngOnInit() {
  }

  onCancel() {
    this.dialog.close();
  }

  onProcced() {
    this.dialog.close('yes');
  }

}
