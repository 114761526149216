import { Component, Inject, OnInit } from "@angular/core";
import { ImageCroppedEvent } from "ngx-image-cropper";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
  selector: "app-image-cropping",
  templateUrl: "./image-cropping.component.html",
  styleUrls: ["./image-cropping.component.scss"],
})
export class ImageCroppingComponent implements OnInit {
  data: any;
  imageChangedEvent: any = "";
  croppedImage: any = "";
  isCropperReady: boolean = false;
  cropper: { x1: number; y1: number; x2: number; y2: number };
  aspectRatio: number = 2 / 2;

  constructor(
    private dialog: MatDialogRef<ImageCroppingComponent>,
    @Inject(MAT_DIALOG_DATA) public fileEventData: any
  ) {
    if (
      this.fileEventData.profileType &&
      this.fileEventData.profileType === "coverFile"
    ) {
      this.aspectRatio = 6 / 2;
      delete this.fileEventData.profileType;
    }
  }

  ngOnInit(): void {
    console.log(this.fileEventData.type);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.dataURLtoFile(
      this.croppedImage,
      this.fileEventData.target.files[0].name
    );
  }

  imageLoaded(image) {
    // show cropper
    // this.isCropperReady = true;
    // if (this.isCropperReady === true) {
    //   setTimeout(() => {
    //     this.cropper = {
    //       x1: 0,
    //       y1: 0,
    //       x2: 410,
    //       y2: 546,
    //     };
    //   });
    //   console.log(this.cropper);
    // }
  }

  cropperReady() {}

  onSave() {
    this.dialog.close(this.croppedImage);
  }

  loadImageFailed() {
    // show message
  }

  onCancel() {
    this.dialog.close();
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    this.croppedImage = new File([u8arr], filename, { type: mime });
    console.log("file croped", this.croppedImage);
  }
}
