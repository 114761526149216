import { Injectable } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { ConfirmationModalComponent } from "./confirmation-modal/confirmation-modal.component";
import { HelpCenterComponent } from "./help-center/help-center.component";
import { ImageCroppingComponent } from "./image-cropping/image-cropping.component";
import { ImagePreviewComponent } from "./image-preview/image-preview.component";
import { MarketUserComponent } from "./market-user/market-user.component";
import { PostViewComponent } from "./post-view/post-view.component";
import { ReportModalComponent } from "./report-modal/report-modal.component";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(public dialog: MatDialog) {}

  ConfirmationDialog(message) {
    let dialogRef: MatDialogRef<ConfirmationModalComponent>;
    dialogRef = this.dialog.open(ConfirmationModalComponent);
    dialogRef.componentInstance.message = message;
    return dialogRef.afterClosed();
  }
  
  helpCenterDialog(message) {
    let dialogRef: MatDialogRef<HelpCenterComponent>;
    dialogRef = this.dialog.open(HelpCenterComponent).addPanelClass('helpCenter');
    dialogRef.componentInstance.message = message;
    return dialogRef.afterClosed();
  }

  marketDialog(message?:any) {
    let dialogRef: MatDialogRef<MarketUserComponent>;
    dialogRef = this.dialog.open(MarketUserComponent);
    dialogRef.componentInstance.message = message;
    return dialogRef.afterClosed();
  }

  imgaePreview(message) {
    let dialogRef: MatDialogRef<ImagePreviewComponent>;
    dialogRef = this.dialog.open(ImagePreviewComponent);
    dialogRef.componentInstance.album = message;
    return dialogRef.afterClosed();
  }

  feedPreview(message) {
    let dialogRef: MatDialogRef<PostViewComponent>;
    dialogRef = this.dialog.open(PostViewComponent);
    dialogRef.componentInstance.dataParam = message;
    return dialogRef.afterClosed();
  }

  reportModal(message) {
    let dialogRef: MatDialogRef<ReportModalComponent>;
    dialogRef = this.dialog.open(ReportModalComponent,{
      panelClass:["rept_des"]
    });
    dialogRef.componentInstance.message = message;
    return dialogRef.afterClosed();
  }

  cropImage(message) {
    let dialogRef: MatDialogRef<ImageCroppingComponent>;
    dialogRef = this.dialog.open(ImageCroppingComponent, {
      width: "50%",
      data: message,
    });
    dialogRef.componentInstance.data = message;
    return dialogRef.afterClosed();
  }
}
